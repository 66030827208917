<template lang="pug">
.vc-slide-youtube-iframe-box
  iframe(
    type="text/html"
    width="100%"
    :src="youtubeEmbedUrl"
    frameborder="0"
    allowfullscreen
  )
</template>

<script>
import youtubeHelperMixin from '@sharedMixins/youtube_helper_mixin.js'

export default {
  // components: {},
  mixins: [youtubeHelperMixin],

  props: {
    slide: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    youtubeEmbedUrl() {
      let url = this.slide.config.youtube_url

      if (url.includes('embed/')) {
        return url
      } else {
        return `https://www.youtube-nocookie.com/embed/${this.getYoutubeId(
          url
        )}`
      }
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
