export default {
  methods: {
    getYoutubeId(url) {
      const regEx =
        '^(?:https?:)?//[^/]*(?:youtube(?:-nocookie)?.com|youtu.be).*[=/]([-\\w]{11})(?:\\?|=|&|$)'
      const match = url.match(regEx)

      if (match) return match[1]
    }
  }
}
